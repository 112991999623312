@import '../variables'

button
  border: none
  cursor: pointer
  font-size: $font-size-15
  font-weight: $font-weight-400
  color: $color-white
  background-color: transparent

  &.gigs-btn
    height: 45px
    line-height: 45px
    border-radius: 22.5px
    padding: 0 30px
    background-color: $color-blue
    position: relative
    overflow: hidden
    transition: background-color 0.5s linear

    &:after
      background: $color-white
      content: ''
      position: absolute
      top: -50px
      left: -120%
      opacity: .2
      transform: rotate(35deg)
      transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1)
      height: 300px
      width: 50px

    &:hover
      background-color: $color-blue-hover

    &:hover, &.run-animation
      &:after
        left: 130%

    &.company
      background-color: $color-purple

      &:hover
        background-color: $color-purple-hover

    &.freelancer
      background-color: $color-green

      &:hover
        background-color: $color-green-hover

    &.rainbow
      background: $gradient, $color-blue

      &:hover
        background: $gradient-hover, $color-blue-hover

    &.grayed
      background-color: #eeeeee
      color: $color-black

      &:hover
        background-color: #e2e2e2

    &.transparent
      border: 2px solid $color-white
      background-color: transparent
      line-height: 41px

      &:after
        background: $color-blue

      &:hover
        background-color: $color-white
        color: $color-blue

    &.centered
      display: inline-flex
      align-items: center

    &.x-loading
      background: linear-gradient(-270deg, $color-blue, $color-blue-hover)
      background-size: 200%
      animation: bg-position-keyframes 0.5s ease-out infinite
      cursor: not-allowed

      &.company
        background: linear-gradient(-270deg, $color-purple, $color-purple-gradient)
        background-size: 200%
        animation: bg-position-keyframes 0.5s ease-out infinite

      &.freelancer
        background: linear-gradient(-270deg, $color-green, $color-green-gradient)
        background-size: 200%
        animation: bg-position-keyframes 0.5s ease-out infinite

    > .gr-icon
      display: none

@media only screen and (min-width: $breakpoint-tablet)
  button.gigs-btn
    > .gr-icon
      display: inline-block
      font-size: 24px

      &.gri-left-arrow
        margin-right: 10px

      &.gri-right-arrow
        margin-left: 10px

@media only screen and (min-width: $breakpoint-small-desktop)
  button.gigs-btn
    height: 50px
    line-height: 50px
    border-radius: 25px

    &.transparent
      line-height: 46px