@import '../variables'

.input-container
  position: relative
  > label
    display: block
    font-size: $font-size-15
    line-height: $font-size-24

  > input, > textarea, > select
    width: 100%
    border-radius: 10px
    padding: 15px 20px
    border: 2px solid #e7e7e7
    font-size: $font-size-15
    line-height: $font-size-24

  &.invalid
    > input, > textarea
      border-color: #f53d7e

  &.valid
    > input, > textarea
      border-color: #14e1cd
  & .select-input-wrapper
    position: relative
    > .choices
      position: absolute
      left: 0
      top: 4px
      z-index: 2
      & .choices__inner
        border: unset
        width: 110px
        position: relative
      & .choices__list--dropdown.is-active
        width: 280px
        position: absolute
    > input
      width: 100%
      border-radius: 10px
      padding: 15px 20px 15px 110px
      border: 2px solid #e7e7e7
      font-size: $font-size-15
      line-height: $font-size-24

label.error
  display: block
  font-size: $font-size-13
  line-height: $font-size-18
  color: #f53d7e
  
label.max-char-counter
  position: absolute
  right: 2px
  top: 0
  font-size: 14px
  color: $color-gray
 
label[for]
  cursor: pointer

  > .required
    color: #f53d7e
    
@media only screen and (min-width: $breakpoint-small-desktop)
  label.max-char-counter
    top: unset