$color-black: #000
$color-charcoil: #242424
$color-dark-gray: #5d5d5d
$color-gray: #bbb
$color-pale-gray: #f5f5f9
$color-white: #FFF
$color-green: #0fb2a1
$color-green-hover: #0d9688
$color-green-gradient: #113845
$color-purple: #8d227f
$color-purple-hover: #731c67
$color-purple-gradient: #5c1647
$color-blue: #535999
$color-blue-hover: #474c85
$color-companies-bg: #fafafc
$color-freelancers-bg: #f3fbfa

$color-shadow-gray: #61616126
$color-white-60: #ffffff99
$color-white-80: #ffffffcc

$gradient: linear-gradient(90deg, #23afa1 0%, #656ea8 50%, #b632b1 100%)
$gradient-hover: linear-gradient(90deg, $color-green-hover 0%, $color-blue-hover 50%, $color-purple-hover 100%)

$box-shadow: 0 3px 5px 3px $color-shadow-gray

$font-size-12: 12px
$font-size-13: 13px
$font-size-14: 14px
$font-size-15: 15px
$font-size-16: 16px
$font-size-18: 18px
$font-size-22: 22px
$font-size-24: 24px
$font-size-25: 25px
$font-size-28: 28px
$font-size-30: 30px
$font-size-35: 35px
$font-size-38: 38px
$font-size-42: 42px
$font-size-50: 50px
$font-size-55: 55px
$font-size-60: 60px
$font-size-66: 66px

$font-weight-300: 300
$font-weight-400: 400
$font-weight-500: 500
$font-weight-600: 600
$font-weight-700: 700

$padding-side-desktop: calc(50% - 700px)
$padding-side-small-desktop: 50px
$padding-side-mobile: 15px

$breakpoint-tablet: 700px
$breakpoint-small-desktop: 1024px
$breakpoint-desktop: 1450px