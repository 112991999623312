@keyframes slide
  0%
    right: 15px

  50%
    right: 50px

  100%
    right: 15px

@keyframes flicker-freelancer
  0%
    color: $color-green

  50%
    color: $color-black

  100%
    color: $color-green

@keyframes flicker-company
  0%
    color: $color-purple

  50%
    color: $color-black

  100%
    color: $color-purple

@keyframes flicker-neutral
  0%
    color: $color-blue

  50%
    color: $color-black

  100%
    color: $color-blue

@keyframes bg-position-keyframes
  0%
    background-position-x: 100%
  100%
    background-position-x: -100%