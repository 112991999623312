@import '../variables'
@import '../icon-font/gr-icons'
@import '../partials/header'
@import '../partials/footer'
@import '../core/components'
@import '../styles/animations'
@import '../styles/texts'
@import '../styles/lists'
@import '../styles/buttons'
@import '../styles/inputs'

*
  box-sizing: border-box
  font-family: Poppins, sans-serif

body
  margin: 0

  &.disable-scroll
    overflow: hidden

  &.show-captcha .grecaptcha-badge
    visibility: visible

  > main
    > section
      &.full-width
        padding: 50px $padding-side-mobile 35px $padding-side-mobile

      &.full-width-no-space
        padding: 0 $padding-side-mobile

      &.narrow
        margin: 0 $padding-side-mobile
        padding: 50px 0 35px 0

      &.narrow-half-space
        margin: 0 $padding-side-mobile
        padding: 25px 0 17px 0

      &.narrow-no-space
        margin: 0 $padding-side-mobile

      &.full-height
        min-height: calc(100vh - 230px)

.underlined
  display: inline-block

  > *
    display: inline-block
    cursor: pointer
    padding: 0 10px
    font-weight: 400

  &.active
    > *
      font-weight: $font-weight-600

    &:after
      display: block
      background: linear-gradient(90deg, $color-green 1.12%, $color-purple 97.52%), $color-blue
      content: ''
      height: 5px
      border-radius: 2.5px

.grecaptcha-badge
  z-index: 110
  visibility: hidden

.logo
  height: 30px

@media only screen and (min-width: 600px)
  .logo
    height: 40px

@media only screen and (min-width: $breakpoint-small-desktop)
  body > main
    > section
      &.full-width
        padding: 110px $padding-side-small-desktop 90px $padding-side-small-desktop

      &.full-width-no-space
        padding: 0 $padding-side-small-desktop

      &.narrow
        margin: 0 $padding-side-small-desktop
        padding: 110px 0 90px 0

      &.narrow-half-space
        margin: 0 $padding-side-small-desktop
        padding: 55px 0 45px 0

      &.narrow-no-space
        margin: 0 $padding-side-small-desktop

@media only screen and (min-width: $breakpoint-desktop)
  body > main
    > section
      &.full-width
        padding: 110px $padding-side-desktop 90px $padding-side-desktop

      &.full-width-no-space
        padding: 0 $padding-side-desktop

      &.narrow
        margin: 0 $padding-side-desktop
        padding: 110px 0 90px 0

      &.narrow-half-space
        margin: 0 $padding-side-desktop
        padding: 55px 0 45px 0

      &.narrow-no-space
        margin: 0 $padding-side-desktop
