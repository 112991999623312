@import '../variables'

@mixin gradient-btn
  padding: 0 1em
  height: 40px
  line-height: 40px
  border-radius: 20px
  background: $gradient, $color-blue
  color: $color-white

body
  padding-top: 4em

header
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: 100
  height: 4em
  background-color: $color-white

  > #logo-holder
    display: flex
    align-items: center
    height: 4em
    padding-left: $padding-side-mobile
    padding-right: $padding-side-mobile
    position: fixed
    top: 0
    left: 0
    right: 0

    > a
      display: flex
      
      &.logo-container
        flex-grow: 1

    > #mobile-menu
      display: flex
      padding-right: 1em

      > .gr-icon
        font-size: 25px

      > #icon-close
        display: none

      > #icon-menu
        display: inline-block

  > nav
    display: none
    background: rgba(0, 0, 0, 0.3)
    backdrop-filter: blur(10px)
    height: 100%
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    overflow-y: auto

    > ul
      list-style: none
      padding: 4.5em 0 0 0
      margin: 0
      background-color: $color-white

      > li:last-of-type
        padding: 2.5em 0

      > li.underlined
        display: block
        text-align: center
        padding-top: 2em

        > a
          font-size: $font-size-16
          line-height: $font-size-16
          text-decoration: none
          text-align: center
          color: $color-black
          letter-spacing: 0.005em

        &.active:after
          display: none

        &.login-btn
          display: none

        &.gradient-btn
          > a
            @include gradient-btn

          &:hover > a
            background: $gradient-hover, $color-blue-hover

  &.expanded
    > nav
      display: block

    > #logo-holder > #mobile-menu
      > #icon-close
        display: inline-block

      > #icon-menu
        display: none

@media only screen and (min-width: 1120px)
  body
    padding-top: 110px

  header
    height: 110px

    > #logo-holder
      display: inline-block
      position: relative
      padding-left: 10px
      padding-top: 2em

      > .login-mobile-btn
        display: none

      > #mobile-menu
        display: none

    > nav
      display: inline-block
      top: 1.75em
      right: 0
      bottom: unset
      left: unset
      height: auto
      background-color: $color-white

      > ul
        padding-top: 0

        > li.underlined
          display: inline-block
          padding-top: 0

          &.active:after
            display: block

          &.login-btn
            display: unset

          &.gradient-btn
            padding: 0 10px

            &.active:after
              display: none
              
@media only screen and (min-width: 1190px)
  header
    > #logo-holder
      padding-left: $padding-side-small-desktop
      
    >nav
      right: $padding-side-small-desktop
      

@media only screen and (min-width: $breakpoint-desktop)
  header
    > #logo-holder
      padding-left: $padding-side-desktop

    > nav
      right: $padding-side-desktop