@import '../variables'

#overlay
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  background-color: #efefef
  z-index: 101
  padding: 0 $padding-side-mobile
  min-height: 100%
  overflow-y: auto

  > .logo
    margin: 30px 0

  > .overlay-content
    flex-grow: 1
    background-color: $color-white
    border-top-right-radius: 30px
    position: relative
    padding: $padding-side-mobile
    min-height: calc(100% - 100px)

    > #close
      font-size: 20px
      color: #bbbbbb
      position: absolute
      top: $padding-side-mobile
      right: $padding-side-mobile
      cursor: pointer

    > .content-container
      min-height: 100%

a.gigs-lnk
  color: $color-blue
  text-decoration: none


.gigs-hint
  position: relative

  > .hint-icon
    cursor: help
    width: 25px
    height: 25px

  > .hint-content
    position: absolute
    top: 30px
    right: 0
    width: 300px
    background-color: $color-white
    box-shadow: $box-shadow
    border-radius: 10px
    padding: 10px
    opacity: 0
    pointer-events: none
    font-size: $font-size-14
    z-index: 103

  &:hover, &.displayed
    > .hint-content
      opacity: 1

.gigs-steps
  display: flex
  align-items: center
  padding-right: 20px

  > .gigs-step
    border-radius: 50%
    font-size: $font-size-12
    color: $color-black
    background-color: #e2e2e2
    width: 32px
    height: 32px
    line-height: 32px
    text-align: center

    &.active
      color: $color-white

  > .spacer
    border-top: 2px solid #e2e2e2
    width: 32px

  &.freelancer
    > .gigs-step.active
      background-color: $color-green

  &.company
    > .gigs-step.active
      background-color: $color-purple

  &.desktop
    display: none

@media only screen and (min-width: $breakpoint-small-desktop)
  #overlay
    padding: 0 10%

    > .overlay-content
      padding: 30px

      > #close
        top: 30px
        right: 30px

  .gigs-steps
    &.desktop
      display: flex

    &.mobile
      display: none

@media only screen and (min-width: $breakpoint-desktop)
  #overlay
    padding: 0 $padding-side-desktop
