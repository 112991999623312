@import '../variables'

ul.gigs-list
  list-style: none
  padding: 0

  > li
    text-indent: -34px
    padding-left: 34px
    @extend p, .normal

    &:nth-child(n+2)
      padding-top: 12px

  > li:before
    display: inline-block
    content: ''
    width: 9px
    height: 9px
    border-radius: 5px
    margin-right: 25px

  &.freelancer
    > li:before
      background-color: $color-green

  &.company
    > li:before
      background-color: $color-purple

ul.gigs-list-2
  list-style: none
  padding: 0 40px
  display: flex
  flex-direction: column

  > li
    padding: 40px 0
    color: $color-white
    flex: 1

    > .gr-icon
      font-size: 60px

    &:nth-child(n+2)
      border-top: 2px solid $color-white

@media only screen and (min-width: $breakpoint-small-desktop)
  ul.gigs-list-2
    flex-direction: row
    padding: 40px 0

    > li
      padding: 0 40px

      &:nth-child(n+2)
        border-top: none
        border-left: 2px solid $color-white