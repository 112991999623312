@import '../variables'

body > footer
  background-color: $color-charcoil

  > .spacer
    height: 15px
    background: linear-gradient(90deg, $color-green 1.12%, $color-purple 97.52%), $color-blue

  > .content
    padding: 15px $padding-side-mobile

    > :nth-child(n+2)
      margin-top: 15px

    > img
      height: 35px

    a
      color: $color-white
      text-decoration: none
      margin-left: 10px

    a:hover
      color: $color-gray

    > a
      font-size: $font-size-14
      font-weight: $font-weight-400
      display: block

    > .contacts
      > a
        font-size: 25px

    > .copyright
      font-size: $font-size-14
      font-weight: $font-weight-400
      color: $color-gray
      margin-bottom: 0

@media only screen and (min-width: $breakpoint-small-desktop)
  body > footer > .content
    padding: 0 $padding-side-small-desktop
    display: grid
    grid-template-columns: 2fr 1fr 1fr 1fr 2fr
    grid-template-rows: 60px 45px

    > :nth-child(n+2)
      margin-top: 0

    > img
      align-self: end

    > a
      justify-self: center
      font-size: $font-size-12

    > a, > .contacts
      grid-row: 1 / span 2
      align-self: center

    > .contacts
      justify-self: end

      > a
        font-size: 40px

    > .copyright
      grid-column: 1
      grid-row: 2
      margin: 0

@media only screen and (min-width: $breakpoint-desktop)
  body > footer > .content
    padding: 0 $padding-side-desktop
    grid-template-columns: 3fr 1fr 1fr 1fr 3fr