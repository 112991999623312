@font-face {
  font-family: "gricons";
  font-display: block;
  src: url("gricons.eot?#iefix") format("embedded-opentype"),
  url("gricons.ttf") format("truetype"),
  url("gricons.woff") format("woff");
}

.gr-icon {
  line-height: 1;
}

.gr-icon:before {
  font-family: gricons !important;
  font-style: normal;
  font-weight: normal !important;
  vertical-align: top;
}

.gri-time:before {
  content: "\e903";
}

.gri-client:before {
  content: "\e900";
}

.gri-goal:before {
  content: "\e901";
}

.gri-skills:before {
  content: "\e902";
}

.gri-skill:before {
  content: "\e933";
}

.gri-appointment:before {
  content: "\e904";
}

.gri-earth:before {
  content: "\e905";
}

.gri-hiring:before {
  content: "\e906";
}

.gri-hiring:before {
  content: "\e906";
}

.gri-salary:before {
  content: "\e908";
}

.gri-add-person:before {
  content: "\e909";
}

.gri-apple:before {
  content: "\e90a";
}

.gri-bidding:before {
  content: "\e90b";
}

.gri-calendar:before {
  content: "\e90c";
}

.gri-chat:before {
  content: "\e90d";
}

.gri-chav-left:before {
  content: "\e90e";
}

.gri-chav-right:before {
  content: "\e90f";
}

.gri-checklist:before {
  content: "\e910";
}

.gri-clock:before {
  content: "\e911";
}

.gri-close:before {
  content: "\e912";
}

.gri-conference:before {
  content: "\e913";
}

.gri-contract:before {
  content: "\e914";
}

.gri-conversation:before {
  content: "\e915";
}

.gri-dumbbell:before {
  content: "\e916";
}

.gri-email:before {
  content: "\e917";
}

.gri-facebook:before {
  content: "\e918";
}

.gri-global-job:before {
  content: "\e919";
}

.gri-growth:before {
  content: "\e91a";
}

.gri-journey:before {
  content: "\e921";
}

.gri-hands:before {
  content: "\e91b";
}

.gri-handshake:before {
  content: "\e91c";
}

.gri-handshake2:before {
  content: "\e91d";
}

.gri-handshake3:before {
  content: "\e91e";
}

.gri-house-plant:before {
  content: "\e91f";
}

.gri-idea:before {
  content: "\e920";
}

.gri-idea:before {
  content: "\e920";
}

.gri-law:before {
  content: "\e922";
}

.gri-left-arrow:before {
  content: "\e923";
}

.gri-legal:before {
  content: "\e924";
}

.gri-linkedin:before {
  content: "\e925";
}

.gri-linkedin2:before {
  content: "\e926";
}

.gri-location:before {
  content: "\e927";
}

.gri-match:before {
  content: "\e928";
}

.gri-nav-close:before {
  content: "\e929";
}

.gri-nav-menu:before {
  content: "\e92a";
}

.gri-person:before {
  content: "\e92b";
}

.gri-phone:before {
  content: "\e92c";
}

.gri-plant:before {
  content: "\e92d";
}

.gri-research:before {
  content: "\e92e";
}

.gri-right-arrow-circle:before {
  content: "\e92f";
}

.gri-right-arrow:before {
  content: "\e930";
}

.gri-roadmap:before {
  content: "\e931";
}

.gri-rocket:before {
  content: "\e932";
}

.gri-rocket:before {
  content: "\e932";
}

.gri-rocket:before {
  content: "\e932";
}

.gri-star:before {
  content: "\e935";
}

.gri-test:before {
  content: "\e936";
}

.gri-twitter:before {
  content: "\e937";
}

.gri-upload:before {
  content: "\e938";
}

.gri-speaker:before {
  content: "\e934";
}
