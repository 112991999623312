@import '../variables'

b
  font-weight: $font-weight-700

h1, h2, h3, h4, h5
  color: $color-black

  &.centered
    text-align: center

  &.no-margin
    margin: 0

  > .highlight-company
    color: $color-purple

    &.run-animation
      animation: flicker-company 0.5s linear

  > .highlight-freelancer
    color: $color-green

    &.run-animation
      animation: flicker-freelancer 0.5s linear

  > .highlight-neutral
    color: $color-blue

    &.run-animation
      animation: flicker-neutral 0.5s linear

h1, h2, h3, h4
  font-weight: $font-weight-700

h5
  font-weight: $font-weight-600

h1
  font-size: $font-size-28
  line-height: $font-size-35

h2, h1.medium
  font-size: $font-size-25
  line-height: $font-size-30

h3, h1.small
  font-size: $font-size-22
  line-height: $font-size-28

h4, h2.small
  font-size: $font-size-18
  line-height: $font-size-24

h5
  font-size: $font-size-14
  line-height: $font-size-18

p
  &.normal, &.normal-spaced
    font-size: $font-size-15
    line-height: $font-size-22
    font-weight: $font-weight-300

  &.normal-bold, &.normal-bold-spaced
    font-size: $font-size-15
    line-height: $font-size-22
    font-weight: $font-weight-500

  &.normal, &.normal-bold
    margin: 0

  &.large
    font-size: $font-size-18
    line-height: $font-size-25
    margin: 0
    font-weight: $font-weight-300

  &.large-bold
    font-size: $font-size-18
    line-height: $font-size-25
    margin: 0
    font-weight: $font-weight-500

  &.small
    font-size: $font-size-13
    line-height: $font-size-16
    margin: 0
    font-weight: $font-weight-300

@media only screen and (min-width: $breakpoint-small-desktop)
  h1
    font-size: $font-size-60
    line-height: $font-size-66

  h2, h1.medium
    font-size: $font-size-50
    line-height: $font-size-55

  h3, h1.small
    font-size: $font-size-38
    line-height: $font-size-42

  h4, h2.small
    font-size: $font-size-28
    line-height: $font-size-35

  h5
    font-size: $font-size-18
    line-height: $font-size-28

  p
    &.normal, &.normal-bold, &.normal-spaced, &.normal-bold-spaced
      font-size: $font-size-18
      line-height: $font-size-28

    &.large, &.large-bold
      font-size: $font-size-22
      line-height: $font-size-28

    &.small
      font-size: $font-size-15
      line-height: $font-size-24